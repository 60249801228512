<template>

    <div class="scored_commentary_tabs_outer" v-if="match">
<cricket-widget />
        <!-- <div class="one_match_details" >
            <div class="one_match_details-head">
                <h2>{{match.tournament.name}} <label>({{match.matchno}})</label>
                </h2>
            </div>
            <div v-if="match.status  != 'Not Started'" :class="(match.old_widget == 0) ? 'clc_widget_crctGround_dtl animation-live' : 'clc_widget_dtl_picth finish-status'">
                <iframe class="clc_widget_dtl_header"
                        id="widgett" :src="('https://kismacricketwidget.6lgx.com/widget.php?match_id='+$route.params.id)"></iframe>
            </div>
            <div class="match_details_header_outer" v-if="match.status  == 'Not Started'">
                <div class="clc_mt_dtl_hdr_table">
                    <div class="clc_team_score_list">
                        <div class="clc_team_name_flag"><h3
                                class="clc_team_name bold_score font-weight-normal">
                            {{match.team1.name}}</h3></div>
                        <div class="clearfix"></div>
                    </div>
                    <div class="clc_team_score_list">
                        <div class="clc_team_name_flag"><h3
                                class="clc_team_name bold_score font-weight-normal">
                            {{match.team2.name}}</h3>
                            <div class="clearfix"></div>
                        </div>
                        <div class="clearfix"></div>
                    </div>
                    <div class="match_details_header_tfoot">
                        <div class="header_tfoot_left"></div>
                        <div class="header_tfoot_right"></div>
                    </div>
                </div>
            </div>
        </div> -->
        <div class="cr-main-tab" v-if="tabs">
            <a @click="scrollLeft" class="prev_btn_dtl_tabs" aria-label="previous arrow" href="javascript:;">
                <img alt="previous arrow" :src="require('@/assets/images/slider_prev_arrow.webp')" width="12" height="21">
            </a>
            <ul class="cr_dtl_tab_mobi">
                <li>
                    <router-link :to="{ name: 'matchDetail.'+tabs[selectedTabIndex], params: { slug: $route.params.slug, id: $route.params.id } }" class="selected">{{tabs[selectedTabIndex].charAt(0).toUpperCase() + tabs[selectedTabIndex].slice(1)}}</router-link>
                </li>
            </ul>
            <ul class="cr_dtl_tab_desk">
                <li v-for="(tab) in tabs">
                    <router-link :class="{ selected: $route.name == 'matchDetail.'+tab }" :to="{ name: 'matchDetail.'+tab, params: { slug: $route.params.slug, id: $route.params.id } }">{{tab.charAt(0).toUpperCase() + tab.slice(1)}}</router-link>
                </li>
            </ul>

            <a @click="scrollRight" class="next_btn_dtl_tabs" aria-label="next arrow" href="javascript:;">
                <img alt="next arrow" :src="require('@/assets/images/slider_next_arrow.webp')" width="12" height="21">
            </a>
        </div>
        <router-view/>
    </div>
</template>

<script>
    import cricketWidget from './cricket-widget';
    import matchDetailTabs from '../Common/match-detail-tabs';
    import moment from "moment";

    export default {
        name: "match-detail",
        data() {
            return {
                match: null,
                tabs: null,
                scrollAmount: 0,
                selectedTabIndex: 0,
            }
        },
        mounted() {
            this.getDetail();
            var _this = this;
            this.$root.$on('CricMatchUpdated', data => {
                if (_this.match && data.match_id == _this.match.id) {
                    _this.getDetail(true)
                }
            });
        },
        beforeDestroy() {
            this.$root.$off('CricMatchUpdated')
        },
        serverPrefetch() {
            return this.getDetail();
        },
        methods: {
            scrollLeft() {
                if(this.selectedTabIndex != 0) {
                    this.selectedTabIndex -= 1;
                    this.$router.push({ name: 'matchDetail.'+this.tabs[this.selectedTabIndex], params: { slug: this.$route.params.slug,id: this.$route.params.id } })
                }
            },

            scrollRight() {
                if((this.selectedTabIndex+1) != this.tabs.length) {
                    this.selectedTabIndex += 1;
                    this.$router.push({ name: 'matchDetail.'+this.tabs[this.selectedTabIndex], params: { slug: this.$route.params.slug,id: this.$route.params.id } })
                }
            },
            async getDetail(cachepurge) {
                await this.$axios
                    .get(this.$MATCH_DETAIL_API.replace(':match', this.$route.params.slug),{headers: cachepurge ? {'cachepurge': true} : {}})
                    .then(response => {
                        this.match = response.data.result.match
                        // Trigger mutation handler below
                        this.$store.commit({
                            type: 'setMatchDetailResponse',
                            data: this.match,
                        });

                    })
                    .catch(error => console.log(error));

                return this.$axios
                    .get(this.$MATCH_DETAIL_TABS_API.replace(':match', this.$route.params.slug))
                    .then(response => {
                        this.tabs = response.data.result;
                        let splitted_route = this.$route.name.split('.');
                        if(splitted_route[1]) {
                            this.selectedTabIndex = this.tabs.indexOf(splitted_route[1]);
                        }
                    })
                    .catch(error => console.log(error));
            },
            moment: function () {
                return moment();
            },
        },

        components: {
            matchDetailTabs,cricketWidget
        },
    }
</script>
<style>




</style>
