<template>

</template>

<script>
    import moment from 'moment';
    export default {
        name: "match-detail-tab",
        data(){
            return {
            }
        },
        mounted() {
        },
        methods: {
            moment: function () {
                return moment();
            },

            getLiveCount() {
            },
        }
    }
</script>
